<style lang="less">
@media (min-width: 1024px) {
	.navContain {
		margin: 0 auto;
		width: 100%;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 6%);
		padding: 20px;
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		.nav {
			// width: 1180px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			.logo {
				width: 120px;
			}
			.title {
				// flex: 1;
				margin-left: 50px;
			}
			.el-menu-item.is-active,
			.el-submenu__title {
				border-bottom: none;
				padding: 0 10px;
			}
			.el-menu-item.is-active.last {
				background-color: #46a853;
				color: #fff !important;
			}
			.el-menu-item {
				border-bottom: none;
				padding: 0 10px;
			}
			.el-menu--horizontal .el-menu-item.is-active,
			.el-submenu__title {
				border-bottom: none !important;
			}
			.el-menu--horizontal .el-menu-item.is-active.last {
				background-color: #46a853;
				color: #fff !important;
			}
			.el-menu--horizontal .el-menu-item {
				border-bottom: none;
				font-weight: 700;
				font-size: 14px;
			}
			.el-menu--horizontal.el-menu .el-menu-item {
				font-size: 18px;
			}
			.el-menu.el-menu--horizontal,
			.el-submenu__title {
				border-bottom: none;
			}
			.el-menu-item.last {
				background-color: #46a853;
				color: #fff !important;
				.free-quote {
					width: 100%;
				}
				a {
					text-decoration: none;
				}
			}
			.el-menu-item.last:hover {
				background-color: #424242 !important;
				color: #fff !important;
			}
			.el-submenu {
				.el-submenu__title {
					font-weight: bold;
					font-size: 18px;
				}
			}
		}
	}
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):not(.last):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):not(.last):hover,
.el-submenu__title a:focus,
.el-submenu__title a:hover,
.el-menu-item:not(.last) a:focus,
.el-menu-item:not(.last) a:hover {
	color: #46a853 !important;
}
.el-menu-item:not(.last) {
	a:hover {
		color: #46a853 !important;
	}
}
.el-menu--popup .el-menu-item a:hover {
	color: #46a853 !important;
}
</style>
<template>
	<div class="navContain">
		<div class="nav">
			<div class="logo">
				<img :src="imgURL + 'logo.png'" alt="" />
			</div>
			<div class="title">
				<el-menu
					class="el-menu-demo"
					mode="horizontal"
					@select="handleSelect"
					text-color="#333"
					active-text-color="#46a853"
				>
					<el-menu-item index="1">
						<x-router-link to="/">Home</x-router-link>
					</el-menu-item>
					<el-submenu index="2">
						<template slot="title">
							<x-router-link to="/">Dropshipping Agent</x-router-link>
						</template>
						<el-menu-item index="2-1">
							<x-router-link to="/3pl-fulfillment">
								3PL Fulfillment Agent
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="2-2">
							<x-router-link to="/china-sourcing-agent">
								Sourcing Agent
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="2-3">
							<x-router-link to="/1688-agent">1688 Agent</x-router-link>
						</el-menu-item>
						<el-menu-item index="2-4">
							<x-router-link to="/taobao-agent-service">
								Taobao Agent
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="2-5">
							<x-router-link to="/amazon-supplier">
								Amazon Supplier
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="2-6">
							<x-router-link to="/aliexpress-agent-service">
								AliExpress Agent Service
							</x-router-link>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<x-router-link to="/our-services">Our Services</x-router-link>
						</template>
						<el-menu-item index="3-1">
							<x-router-link to="/china-sourcing-agent">
								Product Sourcing
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-2">
							<x-router-link to="/product-inspection-service">
								Product Inspection
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-3">
							<x-router-link to="/3pl-fulfillment">
								3PL Fulfillment
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-4">
							<x-router-link to="/product-branding">
								Product Branding
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-5">
							<x-router-link to="/private-label-dropshipping-service">
								Private label dropshipping
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-6">
							<x-router-link to="/1-v-1-customer-service">
								1v1 customer services
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-7">
							<x-router-link to="/after-sale-service">
								After-Sale Services
							</x-router-link>
						</el-menu-item>
						<el-menu-item index="3-8">
							<x-router-link to="/free-product-photo">
								Product Photography
							</x-router-link>
						</el-menu-item>
					</el-submenu>
					<el-menu-item index="4">
						<x-router-link to="/frequently-asked-questions">Faqs</x-router-link>
					</el-menu-item>
					<!-- <el-menu-item index="5">Blog</el-menu-item> -->
					<el-menu-item index="5">
						<x-router-link to="/about-us">About us</x-router-link>
					</el-menu-item>
					<el-menu-item index="6">
						<x-router-link to="/contact-us">Contact us</x-router-link>
					</el-menu-item>
					<el-menu-item index="7">
						<!-- <x-router-link to="/about-us">Customer</x-router-link> -->
						<a href="https://customer.bestfulfill.com.cn/">Customer</a>
					</el-menu-item>
					<!-- <el-menu-item index="7">Contact Us</el-menu-item> -->
					<el-menu-item class="last" index="8">
						<x-router-link to="/get-a-free-quote">
							<div class="free-quote">Get a Free Quick Quote Now</div>
						</x-router-link>
					</el-menu-item>
				</el-menu>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Headers',
	data() {
		return {
			imgURL: process.env.VUE_APP_IMG_URL,
		};
	},
	props: {
		from: {
			type: String,
		},
	},
	created() {},
	mounted() {},
	methods: {
		handleSelect(key, keyPath) {
			// console.log(key, keyPath);
		},
	},
};
</script>
