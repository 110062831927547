<template>
	<div id="app" :style="isMobile ? 'padding-top: 80px' : 'padding-top: 112px'">
		<Headersapp v-if="isMobile" />
		<Headerspc v-else />
		<router-view :key="$route.path + $route.query.t" />
		<Footers />
	</div>
</template>

<style lang="less">
body {
	overflow-x: hidden;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	padding-top: 112px;
}

nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.section-center {
	width: 1200px;
	margin: 0 auto;
}
.el-tooltip__popper {
	font-size: 16px !important;
}
</style>
<script>
export default {
	data() {
		return {
			screenWith: '',
			isMobile: false,
		};
	},
	created() {
		this.screenWith = document.body.clientWidth;
		window.onresize = () => {
			this.screenWith = document.body.clientWidth;
		};
		if (this.screenWith <= 1024) {
			console.log('is mobile');
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	},
	watch: {
		screenWith(newVal, oldVal) {
			let timer = setTimeout(() => {
				if (newVal <= 1024) {
					console.log('is mobile1');
					this.isMobile = true;
				} else {
					this.isMobile = false;
				}
			}, 500);
			return () => {
				clearTimeout(timer);
			};
		},
	},
};
</script>
