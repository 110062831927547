<style lang="less" scoped>
.footer {
	.container {
		background-color: #12141b;
		width: 100%;
		padding: 80px 20%;
		color: #ccc;
		text-align: left;
		font-size: 14px;
		background-image: url('https://bestfulfill.com.cn/bestfulfillImages/footer-bg.png');
	}
	.footerTitle {
		line-height: 30px;
		.title {
			margin-bottom: 20px;
			font-weight: 600;
			font-size: 20px;
			color: #fff;
		}
		a {
			color: #fff;
		}
		.icon {
			img {
				width: 18px;
				height: 18px;
				margin-right: 6px;
			}
		}
		.footerImg {
			img {
				margin-right: 10px;
				margin-top: 20px;
				border-radius: 3px;
			}
		}
	}
	.footer-bottom {
		background: #090a0e;
		padding: 30px 0;
		margin: 0 auto;
		color: #ccc;
		span {
			color: #2ea843;
		}
	}
	.contactUs {
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 2;
		.contactUs-others img {
			display: block;
			margin: 10px 0;
		}
		.el-icon-error {
			color: #46a853;
			font-size: 60px;
		}
		img {
			cursor: pointer;
		}
		.whatsapp-box {
			width: 320px;
			height: 390px;
			border-radius: 5px;
			background-color: #f0ede7;
			position: fixed;
			bottom: 100px;
			right: 30px;
			.header {
				position: relative;
				z-index: 1;
				background: #4aa485;
				padding: 10px 15px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				img {
					width: 28px;
					height: 28px;
					margin-left: -240px;
				}
				.icon {
					width: 22px;
					height: 22px;
					// background: red;
					float: right;
					cursor: pointer;
				}
				.el-icon-arrow-down {
					color: #fff;
					font-size: 22px;
					float: right;
					font-weight: bold;
					cursor: pointer;
				}
			}
			&::before {
				display: block;
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				z-index: 0;
				opacity: 0.035;
				background-image: url('https://bestfulfill.com.cn/bestfulfillImages/whatsapp-bg.png');
			}
			.body {
				width: 90%;
				margin: 0 auto;
				padding-top: 20px;
				margin-bottom: 50px;
				.content {
					padding: 7px 14px 6px;
					background-color: #fff;
					border-radius: 0 10px 10px;
					position: relative;
					transform-origin: center top;
					z-index: 2;
					margin-top: 0;
					margin-left: 0;
					max-width: calc(100% - 40px);
					font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial,
						Ubuntu, Cantarell, Fira Sans, sans-serif;
					font-size: 14px;
					line-height: 18px;
					border: 1px solid rgba(34, 17, 71, 8%);
					margin-bottom: 180px;
					text-align: left;
					.time {
						color: #a1a1a9;
						font-size: 10px;
						line-height: 12px;
					}
				}
				.form {
					/deep/ .el-input__inner {
						background: #fff !important;
						border-radius: 20px;
						border-color: #fff;
						color: #333;
						padding-left: 30px;
					}
					.el-input {
						width: 80%;
						position: relative;
					}
					.img {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background: #c6d7e3;
						display: inline-block;
						margin-left: 10px;
					}
					.emote {
						position: absolute;
						left: 10px;
						top: 9px;
						z-index: 3;
					}
				}
			}
			.closeChat {
				display: block;
				float: right;
			}
		}
	}
}
.el-form {
	.el-form-item {
		/deep/ .el-form-item__content {
			margin-left: 0 !important;
			.el-button {
				width: 100%;
				font-size: 16px;
				font-weight: bold;
				background-color: #46a853;
				border-color: #46a853;
				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #46a853;
				}
			}
			.el-input__inner {
				background-color: rgba(0, 0, 0, 0);
				border-color: #848282;
				color: #fff;
			}
		}
	}
}
.el-button {
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	background-color: #46a853;
	border-color: #46a853;
	a {
		text-decoration: none;
		&:hover {
			color: #46a853 !important;
		}
	}
	&:hover {
		background-color: #fff;
		border-color: #fff;
		color: #46a853;
		a {
			color: #46a853 !important;
		}
	}
}
.emoji-picker {
	position: absolute;
	bottom: 45px;
	right: 30px;
	z-index: 9;
}
@media (max-width: 767px) {
	.footer {
		.container {
			padding: 80px 10%;
		}
		.footerTitle {
			.title {
				margin-top: 30px;
			}
		}
	}
}
</style>
<template>
	<div class="footer">
		<div class="container">
			<div class="row">
				<div class="col-sm-4">
					<img :src="imgURL + 'logo.png'" alt="" />
					<!-- <div style="margin-top: 20px; margin-bottom: 20px">
						Bestfulfill is owned by
						<span style="font-weight: bold">
							Guangzhou Haoqianyi Trading Co., Ltd.
						</span>
					</div> -->
					<div style="margin-top: 20px">
						Our mission is to make your order fulfillment much easier so we can
						save your time and energy, at same time using less money to reach
						the best customer satisfaction rate
					</div>
				</div>
				<div class="col-sm-2 footerTitle">
					<div class="title">Useful Link</div>
					<div v-for="item in footerTitle" :key="item.title">
						<x-router-link :to="item.link">{{ item.title }}</x-router-link>
					</div>
				</div>
				<div class="col-sm-3 footerTitle">
					<div class="title">Contact Info.</div>
					<div class="icon">
						<img :src="imgURL + 'location-dot-solid.png'" alt="" />
						Julong Industrial Zone. GuangZhou, GuangDong, China 510000
					</div>
					<div class="icon">
						<img :src="imgURL + 'phone-solid.png'" alt="" />
						+8615818792913
					</div>
					<div class="icon">
						<img :src="imgURL + 'skype-brands-solid.png'" alt="" />
						live:1607771439
					</div>
					<div class="icon">
						<img :src="imgURL + 'envelope-solid.png'" alt="" />
						info@bestfulfill.com
					</div>
					<div class="footerImg">
						<a href="https://www.facebook.com/bestfulfill" target="_blank">
							<img :src="imgURL + 'footer1.png'" alt="" />
						</a>
						<a
							href="https://www.instagram.com/bestfulfill_official/"
							target="_blank"
						>
							<img :src="imgURL + 'footer2.png'" alt="" />
						</a>
						<a href="http://wa.link/qfma2r" target="_blank">
							<img :src="imgURL + 'footer3.png'" alt="" />
						</a>
						<a href="https://m.me/bestfulfill" target="_blank">
							<img :src="imgURL + 'footer4.png'" alt="" />
						</a>
					</div>
				</div>
				<div class="col-sm-3 footerTitle">
					<div class="title">Newsletter</div>
					<div style="margin-bottom: 20px">
						To get the latest news and latest updates from us.
					</div>
					<!-- <el-form
						:model="inputValidateForm"
						ref="inputValidateForm"
						label-width="100px"
						class="demo-ruleForm"
					>
						<el-form-item
							prop="email"
							:rules="[
								{ required: true, message: 'This field is required.' },
								{
									type: 'email',
									message: 'Please enter the correct email address',
									trigger: ['blur'],
								},
							]"
						>
							<el-input
								v-model="inputValidateForm.email"
								placeholder="Enter your email"
							></el-input>
						</el-form-item>
						<el-form-item>
							<el-button
								type="success"
								@click="submitForm('inputValidateForm')"
							>
								Subscribe
							</el-button>
						</el-form-item>
					</el-form> -->
					<x-router-link to="/contact-us?from=footer">
						<el-button type="success">Subscribe</el-button>
					</x-router-link>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div>
				© 2023
				<span>Guangzhou Haoqianyi Trading Co., Ltd.</span>
				All rights reserved.
			</div>
			<div style="margin-top: 5px">
				<a
					style="color: #ccc"
					href="https://beian.miit.gov.cn/#/Integrated/index"
				>
					粤ICP备2023121338号-2
				</a>
			</div>
		</div>
		<!-- 联系我们 -->
		<div class="contactUs">
			<img
				:src="imgURL + 'open.png'"
				alt=""
				@click="open"
				v-if="isShow == 'open'"
			/>
			<div class="contactUs-others" v-if="isShow == 'others'">
				<el-tooltip effect="light" content="WhatsApp" placement="left">
					<img @click="showChat" :src="imgURL + 'whatsapp.png'" alt="" />
				</el-tooltip>
				<el-tooltip effect="light" content="Skype" placement="left">
					<a href="skype:live:.cid.4c1b89426cf40f49?chat">
						<img :src="imgURL + 'skype.png'" alt="" />
					</a>
				</el-tooltip>
				<el-tooltip
					effect="light"
					content="Facebook Messenger"
					placement="left"
				>
					<a href="https://m.me/bestfulfill">
						<img :src="imgURL + 'facebook.png'" alt="" />
					</a>
				</el-tooltip>
				<el-tooltip effect="light" content="Email" placement="left">
					<a href="mailto:info@bestfulfill.com">
						<img :src="imgURL + 'email.png'" alt="" />
					</a>
				</el-tooltip>
				<img :src="imgURL + 'close.png'" alt="" @click="close" />
			</div>
			<div class="whatsapp-box" v-if="isShowChat">
				<div class="header">
					<img :src="imgURL + 'whatsapp-header.png'" alt="" />
					<div class="icon" @click="closeChat">
						<el-icon class="el-icon-arrow-down"></el-icon>
					</div>
				</div>
				<div class="body">
					<div class="content">
						<p>
							Welcome come to
							<span style="font-weight: 700">Bestfulfill</span>
							, Please leave a message, and We’ll get back to you as soon as …
						</p>
						<div class="time">{{ time }}</div>
					</div>
					<div class="form">
						<el-form
							:model="inputValidateForm1"
							ref="inputValidateForm1"
							label-width="100px"
							class="demo-ruleForm"
						>
							<el-form-item prop="content">
								<img
									@click="toogleDialogEmoji"
									class="emote"
									:src="imgURL + 'emote.png'"
									alt=""
								/>
								<el-input
									ref="input"
									v-model="inputValidateForm1.content"
									placeholder="Write your message..."
									@focus="focus"
								></el-input>
								<div class="img" @click="submit('inputValidateForm1')">
									<img :src="imgURL + 'send.png'" alt="" />
								</div>
								<VEmojiPicker
									v-show="showDialog"
									:pack="emojisNative"
									labelSearch="Search"
									@select="onSelectEmoji"
								/>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<img
					class="closeChat"
					:src="imgURL + 'close.png'"
					alt=""
					@click="closeChat"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { Api } from '@/utils/api';
import { VEmojiPicker } from 'v-emoji-picker';
import packEmoji from '@/data/emojis.js';
export default {
	name: 'Footers',
	components: {
		VEmojiPicker,
	},
	data() {
		return {
			footerTitle: [
				{
					title: 'Home',
					link: '/',
				},
				{
					title: 'Our Services',
					link: '/our-services',
				},
				// {
				// 	title: 'Blog',
				// 	link: '/blog',
				// },
				{
					title: 'About us',
					link: '/about-us',
				},
				{
					title: 'Contact Us',
					link: '/contact-us',
				},
				// {
				// 	title: 'Private Policy',
				// 	link: '/',
				// },
				// {
				// 	title: 'Terms and Conditions',
				// 	link: '/',
				// },
				// {
				// 	title: 'Affiliate Program',
				// 	link: '/',
				// },
			],
			inputValidateForm: {
				email: '',
			},
			imgURL: process.env.VUE_APP_IMG_URL,
			isShow: 'open',
			time: '',
			inputValidateForm1: {
				content: '',
			},
			showDialog: false,
			isShowChat: false,
		};
	},
	created() {},
	mounted() {},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.isSubmit = true;
					Api.submissions(this.inputValidateForm).then((res) => {
						if (res && res.code === 200) {
							this.$message({
								message: 'Your submission was successful.',
								type: 'success',
								offset: '600',
							});
							this.inputValidateForm = {
								email: '',
							};
							this.isSubmit = false;
						} else {
							this.$message.error({
								message: 'Your submission failed because of an error.',
								offset: '600',
							});
						}
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		open() {
			this.isShow = 'others';
		},
		close() {
			this.isShow = 'open';
		},
		showChat() {
			this.isShowChat = true;
			this.isShow = false;
			const now = new Date();
			const hours = now.getHours();
			const minutes = now.getMinutes();
			const hoursFormatted = hours.toString().padStart(2, '0');
			const minutesFormatted = minutes.toString().padStart(2, '0');
			this.time = `${hoursFormatted}:${minutesFormatted}`;
		},
		closeChat() {
			console.log(123456);
			this.isShowChat = false;
			this.isShow = 'open';
		},
		closeChat1(e) {
			e.stopPropagation();
			console.log(333333333);
		},
		toogleDialogEmoji() {
			this.showDialog = !this.showDialog;
		},
		onSelectEmoji(dataEmoji) {
			this.inputValidateForm1.content += dataEmoji.data;
		},
		focus() {
			this.showDialog = false;
		},
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					window.location.href =
						'https://api.whatsapp.com/send?phone=15220782089&text=' +
						this.inputValidateForm1.content;
				}
			});
		},
	},
	computed: {
		emojisNative() {
			return packEmoji;
		},
	},
};
</script>
